import type { NextPage } from 'next';
import Link from 'next/link';
import _ from 'lodash';
import Image from 'next/image';
import { getNews, News } from '../models/dtos';
import NewsGridComponent from '../components/news/news-grid';
import { getPlatforms, Platform } from '../models/Platform';
//import { NewsGridComponent } from 'ui';

type Props = {
	news: News[];
	randomPlatforms: Platform[];
};

const Home: NextPage<Props> = ({ news, randomPlatforms }: Props) => {
	const createCard = (platform: Platform) => {
		return (
			<Link
				href={`#`}
				key={`platform-card-${platform.code}`}
				className="shadow-lg rounded-2xl w-64 m-4 p-4 py-6 bg-white">
				<div className="flex flex-col items-center justify-center">
					<div className="w-24 h-24 rounded-full flex p-1">
						<Image
							src={`/images/platforms/${platform.code}.png`}
							className="object-contain"
							alt={`Descatalogados - Plataforma - Logo de ${platform.name}`}
							title={`Descatalogados - Plataforma - Logo de ${platform.name}`}
							width={88}
							height={88}
							layout="fixed"
						/>
					</div>
					<h2 className="text-gray-800 text-xl font-medium mb-4 mt-4">{platform.name}</h2>
					{/* <h3 className="text-gray-400 text-center text-xs px-2">{brand.shortDescription}</h3> */}
				</div>
			</Link>
		);
	};

	const platforms = randomPlatforms.map((platforms) => {
		return createCard(platforms);
	});

	return (
		<div className="flex flex-col w-full">
			<section className="flex pt-10">
				<div className=" flex-col">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						Últimas noticias
					</h2>
					<NewsGridComponent news={news} />
				</div>
			</section>
			<section className="pt-10">
				<div className=" flex-col">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						Descubre alguna nueva plataforma
					</h2>
					<div className="flex flex-row">{platforms}</div>
				</div>
			</section>
			{/* <section className="pt-10">
				<div className=" flex-col">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						Descubre alguna nueva marca
					</h2>
					<div className="flex flex-row">{brands}</div>
				</div>
			</section>
			<section className="pt-10">
				<div className=" flex-col">
					<h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
						Descubre algún nuevo modelo
					</h2>
					<div>
						<ModelsGridComponent models={randomModels} />
					</div>
				</div>
			</section> */}
		</div>
	);
};

export async function getStaticProps() {
	const platforms = getPlatforms();
	const sPlatforms = _.shuffle(platforms);

	return {
		props: {
			news: getNews(3),
			randomPlatforms: sPlatforms.slice(0, 4),
			title: 'Descatalogados'
		}
	};
}

export default Home;
